<template>
  <v-row class="pl-5">
    <!-- Start: Form area -->
    <form-customer-group class="py-0"></form-customer-group>
    <!-- End: Form area -->
    <!-- Start: Main area -->
    <v-col cols="8" class="overflow-hidden py-0">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-customer-groups="selectedCustomerGroups"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedCustomerGroups="selectedCustomerGroups = $event"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items
          :selected-customer-groups="selectedCustomerGroups"
          @updateSelectedCustomerGroups="selectedCustomerGroups = $event"
        ></data-table-items>
      </div>
      <!-- End: Data table -->
    </v-col>
    <!-- End: Main area -->
  </v-row>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FormCustomerGroup from "./components/FormCustomerGroup";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FormCustomerGroup,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      itemPerPage: 50,
      selectedCustomerGroups: [],
      searchKey: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getCustomerGroups(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getCustomerGroups(route.query);
  },
  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "partner-and-member_customer-groups",
        query: {
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getCustomerGroups(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;

        // Get products
        await this.$store.dispatch("CUSTOMER_GROUP/getCustomerGroups", {
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedCustomerGroups = [];
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedCustomerGroups = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
