<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedCustomerGroups.length > 0"
          btn-class="white rounded-lg ml-3"
          :active-item="false"
          :items="actionList"
          placeholder="Thao tác"
          @select="selectAction($event)"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div class="ml-3" v-if="selectedCustomerGroups.length > 0">
          Đã chọn <strong>{{ this.selectedCustomerGroups.length }}</strong>
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedCustomerGroups: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      searchOptions: [
        { type: "search", label: "Tìm kiếm nhóm khách hàng" }
        // { type: "option/search", label: "Theo mã, tên SKU" },
        // { type: "serial/search", label: "Theo serial" }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["CUSTOMER_GROUP/paginationInfo"];
    },
    customerGroupStatusRequest() {
      return this.$store.getters["CUSTOMER_GROUP/statusRequest"];
    }
  },
  methods: {
    openModal3rdStore() {
      this.$modal.show({
        name: "modal-3rd-store"
      });
    },

    selectAction(val) {
      if (val.id === "delete") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Xóa <strong>${this.selectedCustomerGroups.length}</strong> nhóm khách hàng đã chọn</span>`,
            message:
              "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xóa",
              onClickHandler: async () => {
                const routeQuery = this.$route.query;

                await this.$store.dispatch(
                  "CUSTOMER_GROUP/deleteCustomerGroups",
                  {
                    data: {
                      ids: this.selectedCustomerGroups.map(item => item.id)
                    },
                    routeQuery: {
                      search: routeQuery.search,
                      per_page: parseInt(routeQuery.limit),
                      cur_page: parseInt(routeQuery.page)
                    }
                  }
                );
                if (
                  this.customerGroupStatusRequest.value ===
                  "success-deleteCustomerGroups"
                ) {
                  this.$emit("updateSelectedCustomerGroups", []);
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Đã xóa thành công"
                    }
                  });
                }
              }
            }
          }
        });
      }
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
