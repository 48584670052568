<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="customerGroupStatusRequest.value === 'loading-getCustomerGroups'"
    loading-text="Đang tải dữ liệu"
    :items="customerGroups"
    item-key="id"
    show-select
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.action`]="{ item }">
      <div class="d_flex justify-center alight-center">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            class="mr-2"
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="viewDetail(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="deleteCustomerGroup(item)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedCustomerGroups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Tên nhóm KH",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Mô tả",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    customerGroups() {
      return this.$store.getters["CUSTOMER_GROUP/customerGroups"];
    },
    customerGroupStatusRequest() {
      return this.$store.getters["CUSTOMER_GROUP/statusRequest"];
    },
    selected: {
      get() {
        return this.selectedCustomerGroups;
      },
      set(val) {
        this.$emit("updateSelectedCustomerGroups", val);
      }
    }
  },
  methods: {
    async deleteCustomerGroup(item) {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa nhóm khách hàng <strong>"${item.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch(
                "CUSTOMER_GROUP/deleteCustomerGroups",
                {
                  data: {
                    ids: [item.id]
                  },
                  routeQuery: {
                    search: routeQuery.search,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                }
              );
              if (
                this.customerGroupStatusRequest.value ===
                "success-deleteCustomerGroups"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async viewDetail(item) {
      await this.$store.dispatch(
        "CUSTOMER_GROUP/getCustomerGroupById",
        item.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
