<template>
  <v-col cols="4">
    <div class="font-weight-bold text-h5 mb-4">Nhóm khách hàng</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <v-card-title class="font-weight-bold pa-0 mb-4">
        {{
          customerGroup.id
            ? "Cập nhật nhóm khách hàng"
            : "Thêm mới nhóm khách hàng"
        }}
      </v-card-title>
      <!-- Start: Form -->
      <v-container class="pa-0" fluid>
        <v-row>
          <!-- Start: Name -->
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Tên nhóm</div>
            <v-text-field
              v-model="customerGroup.name"
              class="text-body-1"
              flat
              dense
              outlined
              placeholder="Nhập tên loại sản phẩm"
              solo
              single-line
            ></v-text-field>
          </v-col>
          <!-- End: Name -->
          <!-- Start: Description -->
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Mô tả</div>
            <v-textarea
              v-model="customerGroup.description"
              class="text-body-1"
              flat
              dense
              outlined
              placeholder="Nhập mô tả"
              rows="3"
              single-line
            ></v-textarea>
          </v-col>
          <!-- End: Description -->
          <!-- Start: Conditions -->
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">
              Điều kiện nhóm sản phẩm
            </div>
            <div>
              <tp-input-condition
                v-for="(item, index) in customerGroup.dks"
                :key="index"
                :attributes="customerGroupConditions"
                :value="item"
                @change:name="item.name = $event"
                @change:db="item.db = $event"
                @change:condition="item.dk = $event"
                @change:value="item.list = $event"
                @remove="removeCondition(index)"
              ></tp-input-condition>
            </div>
            <v-btn
              class="rounded-lg mt-2"
              color="grey--text text--darken-2 text-none"
              depressed
              @click="addCondition()"
            >
              Thêm điều kiện
            </v-btn>
          </v-col>
          <!-- End: Conditions -->
        </v-row>
      </v-container>
      <!-- End: Form -->
      <!-- Start: Actions -->
      <v-card-actions class="px-0 mt-12 pb-0">
        <v-btn
          v-if="customerGroup.id"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          :loading="
            customerGroupStatusRequest.value === 'loading-createCustomerGroup'
          "
          @click="updateCustomerGroup()"
        >
          Lưu
        </v-btn>
        <v-btn
          v-else
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          :loading="
            customerGroupStatusRequest.value === 'loading-updateCustomerGroup'
          "
          @click="createCustomerGroup()"
        >
          Thêm
        </v-btn>
        <v-btn
          class="grey lighten-5 rounded-lg mr-2"
          color="grey--text text--darken-1"
          text
          @click="resetCustomerGroup()"
        >
          Hủy
        </v-btn>
      </v-card-actions>
      <!-- End: Actions -->
    </v-card>
  </v-col>
</template>

<script>
export default {
  computed: {
    customerGroup() {
      return this.$store.getters["CUSTOMER_GROUP/customerGroup"];
    },
    customerGroupConditions() {
      return this.$store.getters["CUSTOMER_GROUP/customerGroupConditions"];
    },
    customerGroups() {
      return this.$store.getters["CUSTOMER_GROUP/customerGroups"];
    },
    customerGroupStatusRequest() {
      return this.$store.getters["CUSTOMER_GROUP/statusRequest"];
    }
  },
  created() {
    this.$store.dispatch("CUSTOMER_GROUP/getCustomerGroupConditions");
  },
  methods: {
    addCondition() {
      this.customerGroup.dks.push({
        name: null,
        db: null,
        dk: [],
        list: []
      });
    },

    async createCustomerGroup() {
      const routeQuery = this.$route.query;

      await this.$store.dispatch("CUSTOMER_GROUP/createCustomerGroup", {
        data: this.customerGroup,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.customerGroupStatusRequest.value === "success-createCustomerGroup"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã thêm thành công"
          }
        });
      }
    },

    removeCondition(index) {
      this.customerGroup.dks.splice(index, 1);
    },

    resetCustomerGroup() {
      this.$store.dispatch("CUSTOMER_GROUP/resetCustomerGroup");
    },

    async updateCustomerGroup() {
      const routeQuery = this.$route.query;

      await this.$store.dispatch("CUSTOMER_GROUP/updateCustomerGroup", {
        data: this.customerGroup,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.customerGroupStatusRequest.value === "success-updateCustomerGroup"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu thay đổi"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
